import React from "react"
import styled from "@emotion/styled"
import { connect } from "react-redux"
import spaces from "color-space"

import generateColors from "../utils/colorGen"
import Swatch from "./Swatch"

const SwatchesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const Swatches = ({ baseColor, stops, hueShift, hueMode, luminanceEasing }) => {
  const colorMap = generateColors({
    baseColor,
    stops,
    hueShift,
    mode: hueMode,
    easingY: luminanceEasing,
  })

  return (
    <SwatchesContainer>
      {colorMap.colors.map((color, i) => {
        const stopRgb = spaces[hueMode].rgb(color)
        return (
          <Swatch
            original={i === colorMap.defaultIndex}
            key={stopRgb.map((c) => c.toFixed(0)).join("-")}
            index={i}
            color={stopRgb}
            stops={stops}
          />
        )
      })}
    </SwatchesContainer>
  )
}

const mapStateToProps = state => {
  return {
    baseColor: state.baseColor,
    stops: state.stops,
    hueShift: state.hueShift,
    hueMode: state.hueMode,
    luminanceEasing: state.luminance.easing,
  }
}

export default connect(mapStateToProps)(Swatches)
