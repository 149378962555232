import spaces from "color-space"
import chroma from "chroma-js"

const getExportHeader = ({baseColor, stops, hueShift, luminanceEasing, hueMode, exportHash}) => {
  return (`// Generated by colorlab.dev
// https://colorlab.dev/${exportHash}
//
// Base: ${baseColor.hex()}
// Stops: ${stops}
// Easing: ${luminanceEasing}
// Mode: ${hueMode}
// Hue-shift: ${hueShift.start}, ${hueShift.end}

`);
}

const getStarExportHeader = ({baseColor, stops, hueShift, luminanceEasing, hueMode, exportHash}) => {
  return (`/**
 * Generated by colorlab.dev
 * https://colorlab.dev/${exportHash}
 *
 * Base: ${baseColor.hex()}
 * Stops: ${stops}
 * Easing: ${luminanceEasing}
 * Mode: ${hueMode}
 * Hue-shift: ${hueShift.start}, ${hueShift.end} \n
*/
`);
}

const getXmlExportHeader = ({baseColor, stops, hueShift, luminanceEasing, hueMode, exportHash}) => {
  return (`<!--
  Generated by colorlab.dev
  https://colorlab.dev/${exportHash}
 
  Base: ${baseColor.hex()}
  Stops: ${stops}
  Easing: ${luminanceEasing}
  Mode: ${hueMode}
  Hue-shift: ${hueShift.start}, ${hueShift.end}
--> 
`);
}


const getStop = (color, mode) => {
  const [r, g, b] = color.rgb()
  return {
    hex: color.hex(),
    css: color.css(),
    hsl: color.css("hsl"),
    rgb: { r, g, b },
  }
}

export const jsonExport = (colors, options) => {
  const jsColors = jsExport(colors, options);
  return getExportHeader(options) + JSON.stringify(jsColors, null, 2);
}

const chromafy = (colorMap, options) => {
  return colorMap.colors.map((color) => {
    const stopRgb = spaces[options.hueMode].rgb(color);
    return chroma(stopRgb);
  });
}

export const jsExport = (colors, options) => {
  return colors.reduce((acc, color, i) => {
    const stopColor = getStop(color, options.hueMode)
    acc[i * 10] = stopColor
    if (i === options.defaultIndex) {
      acc.default = stopColor
      acc.defaultStop = i * 10
    }
    return acc
  }, {stops: options.stops})
}

export const cssExport = (colors, options) => {
  const cssVars = colors.reduce((acc, color, i) => {
    const stopColor = `  --color-${i * 10}: ${color.css()};\n`
    acc += stopColor
    if (i === options.defaultIndex) {
      acc += `  --color-default: ${color.css()};\n`
    }
    return acc
  }, '')
  return `${getStarExportHeader(options)}\n:root{\n${cssVars}\n}`;
}

export const swiftExport = (colors, options) => {
  const swiftVars = colors.reduce((acc, color, i) => {
    const [r, g, b] = color.gl();
    const stopColor = `let color${i * 10} = UIColor(red: ${r}, green: ${g}, blue: ${b}, alpha: 1.0);\n`;
    acc += stopColor
    if (i === options.defaultIndex) {
      acc += `let colorDefault = UIColor(red: ${r}, green: ${g}, blue: ${b}, alpha: 1.0);\n`;
    }
    return acc
  }, '');
  return `${getExportHeader(options)}\n${swiftVars}`
}

export const androidExport = (colors, options) => {
  const andrVars = colors.reduce((acc, color, i) => {
    const stopColor = `  <color name="color${i * 10}">${color.hex()}</color>\n`
    acc += stopColor
    if (i === options.defaultIndex) {
      acc += `  <color name="colorDefault">${color.hex()}</color>\n`
    }
    return acc
  }, '')
  return `
<!--?xml version="1.0" encoding="UTF-8"?-->
${getXmlExportHeader(options)}
<resources>
${andrVars}
</resources>
`;
}

export const generateExport = (colorMap, options) => {
  const chromaStops = chromafy(colorMap, options);
  return {
    js: jsExport(chromaStops, options),
    json: jsonExport(chromaStops, options),
    css: cssExport(chromaStops, options),
    swift: swiftExport(chromaStops, options),
    android: androidExport(chromaStops, options)
  };
}

export default generateExport;
