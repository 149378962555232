import React, {useEffect} from "react"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/core"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {connect} from 'react-redux';

import Controls from "../components/Controls"
import Swatches from "../components/Swatches"
import {resetState} from '../ducks/AppState';
import {restoreStateHash, restoreState} from '../utils/serializeState';

// Import fonts
require("typeface-source-code-pro")

toast.configure();

const loadFromLocal = () => {
  try {
    const persistedState = localStorage.getItem('colorRamp');
    if (persistedState === null) {
      return undefined;
    }
    return restoreState(persistedState);
  } catch (err) {
    return undefined;
  }
}

const loadFromHash = (location) => {
  if (location.pathname.match(/^\/[\w0-9+=]+$/)) {
    const hash = location.pathname.substr(1);
    const hashState = restoreStateHash(hash);
    if (hashState) {
      return hashState;
    }
  }
  return undefined;
}

const PageLayout = styled.div`
  display: flex;
`
const Index = ({location, resetState}) => {

  useEffect(() => {
    const localSave = loadFromLocal();
    const hashSave = loadFromHash(location);
    if (hashSave || localSave) {
      resetState(hashSave || localSave);
    }
  }, [])

  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
          }
          html,
          body {
            margin: 0;
            font-family: "Source Code Pro";
          }
          input {
            font-family: "Source Code Pro";
            width: 100%;
            font-size: 1rem;
            padding: 0.25rem;
          }
        `}
      />
      <PageLayout>
        <Controls />
        <Swatches />
      </PageLayout>
    </>
  )
}

export default connect(null, {resetState})(Index);
