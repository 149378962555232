import React, { useState } from "react"
import { connect } from "react-redux"
import { setHueShift, setHueMode, setLuminanceEasing } from "../ducks/AppState"
import styled from "@emotion/styled"

import EasingSelect from "./EasingSelect"

const AdvancedToggle = styled.div`
  cursor: pointer;
  margin: 0.5rem 0;
`

const AdvancedButton = styled.h2`
  margin: 0.5rem 0 0 0;
`

const AdvancedFields = ({
  startShift,
  endShift,
  setHueShift,
  hueMode,
  setHueMode,
  luminanceEasing,
  setLuminanceEasing
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <AdvancedToggle 
        tabIndex="0"
        role="button"
        aria-pressed={open}
        onClick={() => setOpen(!open)}
        onKeyDown={(e) => {
          // Enter: 13, Space: 32
          if (e.which === 13 || e.which === 32) {
            setOpen(!open);
          }
        }}
      >
        <AdvancedButton>Advanced {open ? "-" : "+"}</AdvancedButton>
      </AdvancedToggle>
      <div style={{ display: open ? "block" : "none", marginBottom: '0.5rem' }}>
        <h3>Luminance Easing</h3>
        <EasingSelect onChange={setLuminanceEasing} value={luminanceEasing} />
        <h3>Hue Shift</h3>
        <div>{"Shift L > Base (Lighter)"}</div>
        <input
          value={startShift}
          onChange={e => setHueShift("start", e.target.value)}
          type="number"
        />
        <div>{"Shift L < Base (Darker)"}</div>
        <input
          value={endShift}
          onChange={e => setHueShift("end", e.target.value)}
          type="number"
        />

        <h3>Mode</h3>
        <select value={hueMode} onChange={e => setHueMode(e.target.value)}>
          <option value="hsluv">Hsluv</option>
          <option value="lchab">Lchab</option>
          <option value="hpluv">Hpluv</option>
        </select>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    startShift: state.hueShift.start,
    endShift: state.hueShift.end,
    hueMode: state.hueMode,
    luminanceEasing: state.luminance.easing
  }
}

export default connect(
  mapStateToProps,
  {
    setHueMode,
    setHueShift,
    setLuminanceEasing
  }
)(AdvancedFields)
