import React, { useState } from "react"
import ReactModal from "react-modal"
import { connect } from "react-redux"
import styled from '@emotion/styled';
import {css} from '@emotion/core';
import copy from 'copy-to-clipboard';
import {toast, Flip} from 'react-toastify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';

import generateColors from "../utils/colorGen"
import PrismCode from './PrismCode';
import {resetState} from '../ducks/AppState';
import {createStateHash} from '../utils/serializeState';
import generateExport from '../utils/jsonExport';

ReactModal.setAppElement('#___gatsby')


const ExportButton = styled.button`
  ${({colors, invert}) => {
    if (colors.stops > 5) {
      return css`
        color: ${colors[(colors.stops - 2) * 10].hex};
        background-color: ${invert ? colors[0].hex : colors[10].hex};
        border-color: ${colors[(colors.stops - 3) * 10].hex};
        &:hover {
          color: ${colors[(colors.stops - 3) * 10].hex};
          background-color: ${invert ? colors[20].hex : colors[0].hex};
        }
        &:focus {
          box-shadow: 1px 1px 5px ${colors[(colors.stops - 3) * 10].hex} 
        }
      `;
    }
    if (colors.stops > 2) {
      return css`
        color: ${colors[(colors.stops - 1) * 10].hex};
        background-color: ${colors[0].hex};
        border-color: ${colors[(colors.stops - 1) * 10].hex};
        &:hover {
          background-color: ${colors[10].hex};
        }
        &:focus {
          box-shadow: 1px 1px 5px ${colors[(colors.stops - 1) * 10].hex} 
        }
      `;
    }

    return css`
      background-color: #16161d;
      color: #e5e4e2;
      border-color: #e5e4e2;
      &:hover {
        background-color: #484858;
        color: #fff;
      }
      &:focus {
        box-shadow: 1px 1px 5px #16161d;
      }
    `;
  }};
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-property: transform, background-color, color;
  border-radius: 0.2rem;
  float: left;
	display: block;
	padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  font-size: 1rem;
	vertical-align: middle;
	position: relative;
  font-family: 'Source Code Pro';
  &:focus {
    outline: none;
  }
  &:hover {
    transform: translate(0, -2px);
  }
  &:active {
    transform: translate(0, 0);
    transition-duration: 0.05s;
  }
`

const ExportContainer = styled.div`
  margin: 0.5rem 0;
`;

const ExportCode = styled(PrismCode)`
  overflow: auto;
`

const ExportContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const copyCode = (copyString) => {
  copy(copyString);
  toast.success('Copied to clipboard!', {autoClose: 2000, position: 'top-right', hideProgressBar: true, transition: Flip})
}


const Export = ({ baseColor, stops, hueShift, hueMode, luminanceEasing, resetState }) => {
  const [open, setOpen] = useState(false)
  const [tabIndex, setTabIndex] = useState(0);
  stops = Math.min(Math.max(stops, 1), 100);

  const colorMap = generateColors({
    baseColor,
    stops,
    hueShift,
    mode: hueMode,
    easingY: luminanceEasing,
  })

  const exportHash = createStateHash({
    baseColor,
    stops,
    hueShift,
    hueMode,
    luminance: {
      easing: luminanceEasing
    }
  });


  const exportOptions = {baseColor, stops, hueShift, luminanceEasing, hueMode, exportHash, defaultIndex: colorMap.defaultIndex}
  const exports = generateExport(colorMap, exportOptions);
  const colors = exports.js;

  return (
    <ExportContainer>
      <ExportButton colors={colors} onClick={() => setOpen(true)}>Export</ExportButton>
      <ExportButton colors={colors} onClick={() => resetState()}>Reset</ExportButton>
      <ReactModal
        isOpen={open}
        contentLabel="EportColors"
        onRequestClose={() => setOpen(false)}
      >
        <ExportContent>
          <div css={css`padding: 0.5rem 0;`}>
            <span>Permalink: </span><a href={`/${exportHash}`}>{`https://colorlab.dev/${exportHash}`}</a>
          </div>
          <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)} css={css`height: 100%; display: flex; flex-direction: column;`}>
          <TabList>
            <Tab>JS / JSON</Tab>
            <Tab>CSS</Tab>
            <Tab>Swift</Tab>
            <Tab>Android</Tab>
          </TabList>
          <TabPanel style={{height: '100%', overflow: 'auto'}}>
            <ExportCode
              onClick={() => copyCode(exports.json)}
              language="javascript"
            >
              {exports.json}
            </ExportCode>
          </TabPanel>

          <TabPanel style={{height: '100%', overflow: 'auto'}}>
            <ExportCode
              onClick={() => copyCode(exports.css)}
              language="css"
            >
              {exports.css}
            </ExportCode>
          </TabPanel>

          <TabPanel style={{height: '100%', overflow: 'auto'}}>
            <ExportCode
              onClick={() => copyCode(exports.swift)}
              language="swift"
            >
              {exports.swift}
            </ExportCode>
          </TabPanel>
          <TabPanel style={{height: '100%', overflow: 'auto'}}>
            <ExportCode
              onClick={() => copyCode(exports.android)}
              language="xml"
            >
              {exports.android}
            </ExportCode>
          </TabPanel>
          </Tabs>
          <div css={css`padding: 0.5rem 0 0 0;`}>
            <ExportButton colors={colors} onClick={() => {
              copyCode([exports.json, exports.css, exports.swift, exports.android][tabIndex])
            }}>Copy</ExportButton>
            <ExportButton invert colors={colors} onClick={() => setOpen(false)}>Close</ExportButton>
          </div>
        </ExportContent>
      </ReactModal>
    </ExportContainer>
  )
}

const mapStateToProps = state => {
  return {
    baseColor: state.baseColor,
    stops: state.stops,
    hueShift: state.hueShift,
    hueMode: state.hueMode,
    luminanceEasing: state.luminance.easing
  }
}

export default connect(mapStateToProps, {resetState})(Export)
