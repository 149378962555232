import React from "react"
import styled from "@emotion/styled"

//import chroma from 'chroma-js';
import { EditableInput } from "react-color/lib/components/common"

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const InputLabel = styled.div`
  margin: 0.5rem 0 0.25rem 0;
`

const customInputStyles = {
  wrap: {
    width: "100%",
  },
  input: {
    width: "100%",
    padding: "0.25rem",
    fontSize: "1rem",
  },
  label: {
    display: "none",
  },
}

const FieldSet = props => {
  const handleChange = (data, e) => {
    if (data.H || data.S || data.L) {
      props.onChange({
        h: data.H || props.hsl.h,
        s: data.S || props.hsl.s,
        l: data.L || props.hsl.l,
        source: "hsl",
      })
    }
    if (data.Hex && /#[0-9A-Fa-f]{6}/.test(data.Hex)) {
      props.onChange({
        hex: data.Hex,
        source: "hex",
      })
    }
  }

  return (
    <InputContainer>
      <InputLabel>Hex Code</InputLabel>
      <EditableInput
        style={customInputStyles}
        label="Hex"
        value={props.hex}
        onChange={handleChange}
      />
      <InputLabel>Hue</InputLabel>
      <EditableInput
        style={customInputStyles}
        label="H"
        value={props.hsl.h.toFixed(0)}
        onChange={handleChange}
      />
      <InputLabel>Saturation</InputLabel>
      <EditableInput
        style={customInputStyles}
        label="S"
        value={props.hsl.s}
        onChange={handleChange}
      />
      <InputLabel>Lightness</InputLabel>
      <EditableInput
        style={customInputStyles}
        label="L"
        value={props.hsl.l}
        onChange={handleChange}
      />
    </InputContainer>
  )
}

export default FieldSet
