import React, {useEffect, useRef} from 'react';
import prism from 'prismjs';
import 'prismjs/components/prism-swift';
import 'prismjs/themes/prism.css';

const PrismCode = ({children, language, ...props}) => {
  const codeBlock = useRef(null);

  useEffect(() => {
    if (codeBlock && codeBlock.current) {
      prism.highlightElement(codeBlock.current);
    }
  });

  return (
    <pre {...props}>
      <code ref={codeBlock} className={`language-${language}`}>
        {children}
      </code>
    </pre>
  )
}

export default PrismCode;
