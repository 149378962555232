import React from "react"
import styled from "@emotion/styled"

//import chroma from 'chroma-js';
import { CustomPicker } from "react-color"
import { Hue, Saturation } from "react-color/lib/components/common"

import FieldSet from "./FieldSet"

const PickerContainer = styled.div``

const Pointer = styled.div`
  width: 0.5rem;
  height: 2rem;
  border-radius: 0.15rem;
  box-shadow: rgba(0, 0, 0, 0.6) 0 0 2px;
  background-color: #fff;
  position: relative;
  transform: translate(-0.25rem, -0.25rem);
`

const HueContainer = styled.div`
  position: relative;
  width: 100%;
  height: 1.5rem;
  margin-top: 0.5rem;
`

const SaturationContainer = styled.div`
  position: relative;
  width: 100%;
  height 120px;
`

const PickerSwatch = styled.div`
  width: 100%;
  height: 2rem;
  margin: 0.5rem 0;
  background-color: ${({ color }) => color};
`

const Picker = props => {
  return (
    <PickerContainer>
      <SaturationContainer>
        <Saturation {...props} />
      </SaturationContainer>
      <HueContainer>
        <Hue {...props} pointer={Pointer} />
      </HueContainer>
      <PickerSwatch color={props.hex} />
      <FieldSet {...props} />
    </PickerContainer>
  )
}

export default CustomPicker(Picker)
