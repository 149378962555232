import * as easing from "js-easing-functions"
import spaces from "color-space"

easing.linear = (i, initial, step, stops) => {
  return step * (i / stops) + initial
}

function indexOfClosest(arr, target) {
  let closest = Number.MAX_SAFE_INTEGER
  let index = 0
  arr.forEach((num, i) => {
    let dist = Math.abs(num - target)
    if (dist < closest) {
      closest = dist
      index = i
    }
  })
  return index
}


const generateColors = ({ baseColor, stops, hueShift, mode, easingY }) => {
  stops = Math.min(Math.max(stops, 1), 100);

  let Ymap = []
  let Hmap = []
  let ret = { defaultIndex: null, colors: null, stops: stops }
  const baseRep = spaces.rgb[mode](baseColor.rgb())

  const uvMode = mode === 'hsluv' || mode === 'hpluv';

  let minTarget = 2
  let maxTarget = 99
  if (!uvMode) {
    minTarget = 0.4
    maxTarget = 110
  }

  for (let i = 0; i < stops; i++) {
    Ymap.push(easing[easingY](i, maxTarget, minTarget - maxTarget, stops))
  }
  const swapIndex = indexOfClosest(
    Ymap,
    uvMode ? baseRep[2] : baseRep[0]
  )
  const preSwapCount = swapIndex
  const postSwapCount = stops - swapIndex - 1

  for (let j = 0; j < stops; j++) {
    if (j < swapIndex) {
      Hmap.push((hueShift.start / preSwapCount) * (preSwapCount - j))
    } else if (j > swapIndex) {
      Hmap.push((hueShift.end / postSwapCount) * (j - swapIndex))
    } else {
      Hmap.push(0)
    }
  }

  ret.colors = Ymap.map((lightness, i) => {
    if (i === swapIndex) {
      return baseRep
    }
    if (uvMode) {
      return [baseRep[0] + Hmap[i], baseRep[1], lightness]
    } else {
      return [lightness, baseRep[1], baseRep[2] + Hmap[i]]
    }
  })
  ret.defaultIndex = swapIndex

  return ret
}

export default generateColors
