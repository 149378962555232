import React from "react"
import styled from "@emotion/styled"
import { connect } from "react-redux"

import Picker from "./Picker"
import {
  setBaseColor,
  setStopCount,
  setHueShift,
  setHueMode,
  clampStopCount
} from "../ducks/AppState"
import AdvancedFields from "./AdvancedFields"
import Export from "./Export"

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  width: 320px;
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const Controls = ({
  baseColor,
  setBaseColor,
  stops,
  setStopCount,
  clampStopCount
}) => {
  return (
    <LayoutContainer>
      <h1>Color Lab</h1>
      <div>
        <h3>Stops</h3>
        <input
          value={stops}
          onChange={e => setStopCount(e.target.value)}
          onBlur={(e) => clampStopCount(e.target.value)}
          type="number"
          min={1}
          max={100}
        />
        <h3>Base Color</h3>
        <Picker
          color={baseColor.hex()}
          onChange={color => setBaseColor(color.hex)}
        />
      </div>
      <AdvancedFields />
      <Export />
    </LayoutContainer>
  )
}

const mapStateToProps = state => {
  return {
    baseColor: state.baseColor,
    stops: state.stops
  }
}

export default connect(
  mapStateToProps,
  { setBaseColor, setStopCount, setHueShift, setHueMode, clampStopCount }
)(Controls)
