import React from "react"
import styled from "@emotion/styled"
import {css} from '@emotion/core';

import chroma from "chroma-js"
import Icon from '@mdi/react';
import {mdiContentCopy } from '@mdi/js';
import copy from 'copy-to-clipboard';
import {toast, Flip} from 'react-toastify';

const ActionIconButton = styled.span``;

const ActionSection = styled.div`
  position: absolute;
  right: 1rem;
`;

const SwatchItem = styled.div`
  background-color: ${({ color }) => `rgb(${color.join(",")})`};
  cursor: pointer;
  height: ${({ stops }) => 100 / stops + "vh"};
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
  align-items: center;
  ${ActionIconButton} {
    display: none;
  }
  &:hover {
    ${ActionIconButton} {
      display: inline-block;
    }
  }
`

const Swatch = ({ color, original, index, stops }) => {
  const contrastColor = chroma.contrast(color, "white") > 4.5 ? "#fff" : "#000";
  return (
    <SwatchItem color={color} stops={stops} onClick={() => {
      copy(chroma(color).hex());
      toast.success(`Copied hex code for stop ${index * 10}`, {autoClose: 2000, position: 'top-right', hideProgressBar: true, transition: Flip})
    }}>
      <span css={css`color: ${contrastColor};`}>
        {index * 10} {original && "*"}
      </span>
      <span css={css`color: #fff; text-shadow: 1px 1px 1px #606060;`}>{chroma.contrast(color, "white").toFixed(3)}</span>
      <span css={css`color: #000; text-shadow: 1px 1px 1px #919191;`}>{chroma.contrast(color, "black").toFixed(3)}</span>
      <span css={css`color: ${contrastColor};`}>{chroma(color).hex()}</span>
      <ActionSection>
        <ActionIconButton>
          <Icon 
            path={mdiContentCopy}
            title="Copy hex code"
            size={1}
            color={contrastColor}
          />
        </ActionIconButton>
      </ActionSection>
    </SwatchItem>
  )
}

Swatch.displayName = 'Swatch'

export default Swatch
