import React from "react"

const EasingSelect = ({ value, onChange }) => {
  return (
    <select value={value} onChange={e => onChange(e.target.value)}>
      <optgroup label="Linear">
        <option value="linear">Linear</option>
      </optgroup>

      <optgroup label="Quadratic">
        <option value="easeInQuad">Ease In Queadratic</option>
        <option value="easeOutQuad">Ease Out Queadratic</option>
        <option value="easeInOutQuad">Ease In-Out Queadratic</option>
      </optgroup>

      <optgroup label="Cubic">
        <option value="easeInCubic">Ease In Cubic</option>
        <option value="easeOutCubic">Ease Out Cubic</option>
        <option value="easeInOutCubic">Ease In-Out Cubic</option>
      </optgroup>

      <optgroup label="Trig">
        <option value="easeInSine">Ease In Sine</option>
        <option value="easeOutSine">Ease Out Sine</option>
        <option value="easeInOutSine">Ease In-Out Sine</option>
      </optgroup>

      <optgroup label="Circle">
        <option value="easeInCirc">Ease In Circular</option>
        <option value="easeOutCirc">Ease Out Circular</option>
        <option value="easeInOutCirc">Ease In-Out Circular</option>
      </optgroup>
    </select>
  )
}

export default EasingSelect
